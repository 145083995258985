import { CLink } from '@/CLink';
import { SecondaryBtn } from '@/buttons';
import { ReactComponent as Logo } from '@a/images/layout/logo.svg';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import useLocationData from '@s/hooks/useLocationData';
import { navigate } from 'gatsby';
import { Fragment, useEffect, useRef, useState } from 'react';
import { DesktopNav, NavItem } from './DesktopNavPrimitive';
// import { MobNav } from './MobNav';
import useIntersectionObserver from '@s/hooks/useIntersectionObserver';
import { MobNav } from './MobNav';
import { navLinks } from './NavLinks';
import { theme } from './styles/GlobalStyles';
import { flexSpace, px } from './styles/classes';

const Navbar = styled.header<{ scrolled: boolean }>`
    ${px};
    padding-top: 12px;
    padding-bottom: 12px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    margin-right: var(--removed-body-scroll-bar-size);
    z-index: 100;
    border-bottom: 1px solid transparent;
    transition: background-color 0.3s ease-in-out, border-color 0.3s ease-in-out;

    > div {
        ${flexSpace};
        max-width: 1164px;
        margin: 0 auto;
    }

    ${({ scrolled, theme }) =>
        scrolled &&
        css`
            background-color: ${theme.colors.blue} !important;
            border-color: ${theme.colors.red};
        `};
`;

const HomeLink = styled(CLink)`
    display: flex;
    flex-shrink: 0;

    > svg path {
        transition: fill 0.3s ease-in-out;
    }

    :hover {
        path {
            fill: ${({ theme }) => theme.colors.red};
        }
    }

    :focus-visible {
        path {
            fill: ${({ theme }) => theme.colors.red};
        }
    }
`;

const DesktopNavMenu = styled(DesktopNav)`
    width: 100%;
    display: none;

    @media (min-width: 1024px) {
        display: block;
    }
`;

const menuStyles = css`
    > li {
        position: relative; // for submenu align with item

        :first-of-type {
            margin-left: auto;
        }

        :nth-last-of-type(2) {
            > a {
                margin-right: 0 !important;
            }
        }

        :last-of-type {
            margin-left: auto;
        }
    }
`;

const contentStyles = css`
    box-shadow: 0px 16px 24px -12px rgba(213, 12, 57, 0.62);
    background-color: ${theme.colors.dark};
    border-radius: 16px;
    overflow: hidden;
    position: absolute;
    top: calc((1rem * 1.6968) + 32px);
    width: max-content;
    z-index: 100;
    left: 50%;
    transform: translateX(-50%);

    > ul {
        max-height: calc(100vh - 175px);
        overflow-y: auto;
    }

    @media (min-width: 1440px) {
        top: calc((1rem * 1.6968) + 48px);
    }
`;

export const scrollToForm = () => {
    const element = document.querySelector('#contact');
    const y = element && element.getBoundingClientRect().top + window.scrollY - 112;
    window.scroll({
        behavior: 'smooth',
        top: y || 0,
    });
};

const Observe = styled.span`
    position: absolute;
    width: 1px;
    height: 1px;
    top: 10px;
    left: 0;
`;

export const LogoLink = () => (
    <HomeLink to="/">
        <Logo aria-label="FT Mayers Testosterone" />
    </HomeLink>
);

export const Nav = () => {
    const { title } = useLocationData();

    const [scrolled, setScrolled] = useState(false);

    const observeRef = useRef<HTMLElement | null>(null);

    const visible = useIntersectionObserver(observeRef, {});

    useEffect(() => {
        const timeout = setTimeout(() => {
            setScrolled(!visible);
        }, 100);
        return () => {
            clearTimeout(timeout);
        };
    }, [visible]);

    return (
        <Fragment>
            <Navbar scrolled={scrolled}>
                <div>
                    <LogoLink />

                    <DesktopNavMenu
                        menuStyles={menuStyles}
                        contentStyles={contentStyles}
                        links={navLinks.slice(0, -1)}
                    >
                        <NavItem>
                            <SecondaryBtn
                                onClick={() =>
                                    title === 'contact us'
                                        ? navigate('/', {
                                              state: {
                                                  scrollToForm: true,
                                              },
                                          })
                                        : scrollToForm()
                                }
                                css={css`
                                    @media (max-width: 1023px) {
                                        display: none;
                                    }
                                `}
                            >
                                {navLinks[navLinks.length - 1].text}
                            </SecondaryBtn>
                        </NavItem>
                    </DesktopNavMenu>

                    <MobNav />
                </div>
            </Navbar>
            <Observe ref={observeRef} />
        </Fragment>
    );
};
